import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import MainApiActions from "actions/MainApiActions";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const DocumentPreview = (props) => {
  const {
    className,
    error,
    previewPageMeta: { angle } = {},
    documentPreview,
    children,
    onClick
  } = props;

  const dispatch = useDispatch();

  const [imageSrc, setImageSrc] = useState(null);

  const [imageLoaded, setImageLoaded] = useState(false);

  const imageStyle = useMemo(() => {
    return { transform: `rotate(${Utils.normalizeAngle(angle)}deg)` };
  }, [angle]);

  const fetchData = useCallback(async() => {
    setImageLoaded(false);
    setImageSrc(null);

    const response = await dispatch(MainApiActions.fetchAttachmentUrl(documentPreview, false, true));

    setImageSrc(response);
    await Utils.preloadImages([response]);
    setImageLoaded(true);

    return response;
  }, [dispatch, documentPreview]);

  const handlePreviewClick = useCallback(async() => {
    if (!imageSrc) return;

    if (onClick) {
      onClick();

      return;
    }

    dispatch(UiActions.showModalImages([""]));
    try {
      await Utils.preloadImages([imageSrc]);
      dispatch(UiActions.showModalImages([imageSrc]));
    } catch (exception) {
      const response = await fetchData();

      dispatch(UiActions.showModalImages([response]));
    }
  }, [dispatch, imageSrc, fetchData, onClick]);

  useEffect(() => {
    if (imageSrc || !documentPreview) return;
    fetchData();
  }, [imageSrc, documentPreview, fetchData]);

  return (
    <div className={classNames(Css.documentPreview, className)}>
      <div className={Css.container}>
        {imageSrc && (
          <div className={Css.image} onClick={handlePreviewClick}>
            <img src={imageSrc} style={imageStyle} />
            <div className={Css.icon}>
              <Icons.ArrowsOut />
            </div>
          </div>
        )}
        {(!documentPreview || !imageLoaded) && !error && (
          <Preloader absolute className={Css.preloader} />
        )}
        {!documentPreview && error && (
          <Icons.FileX className={Css.error} />
        )}
      </div>
      {children}
    </div>
  );
};

export default React.memo(DocumentPreview);
