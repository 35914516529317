import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getDocumentsStats, getUploadingDocumentsCount } from "selectors/documents";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Filters from "./lib/Filters";
import ListItem from "./lib/ListItem";
import ListItemDetails from "./lib/ListItemDetails";
import NoDataContent from "nlib/common/NoDataContent";
import PageActionButtons from "mlib/common/PageActionButtons";
import Pagination from "nlib/ui/Pagination";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo } from "react";
import UploadDocumentsModal from "./lib/UploadDocumentsModal";
import UploadFilesButton from "nlib/ui/UploadFilesButton";
import Utils from "utils/Utils";
import classNames from "classnames";
import useDocuments from "hooks/useDocuments";
import useEnvVars from "hooks/useEnvVars";
import useShowModal from "hooks/useShowModal";
import useUploadDocuments from "hooks/useUploadDocuments";

const { STATUSES } = DataConstants;

const FILE_DROP_ALLOWED_TYPES = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const MAX_DISPLAYED_PAGES = 5;

const DocumentsPage = () => {
  const [envVars, setEnvVars] = useEnvVars();

  const { page = 1, limit = Constants.TABLE_PAGE_SIZE, editItem } = envVars;

  const statuses = useMemo(() => {
    return [STATUSES.TO_EXTRACT, STATUSES.TO_REVIEW];
  }, []);

  const {
    fetching: documentsFetching,
    data: documentsData,
    refetch: refetchDocuments
  } = useDocuments({ status: statuses });

  const { uiTexts } = useSelector(getTextsData);

  const documentsStats = useSelector(getDocumentsStats);

  const uploadDocuments = useUploadDocuments();

  const [
    uploadDocumentsModalData,
    showUploadDocumentsModal,
    onUploadDocumentsModalClose
  ] = useShowModal();

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const [editItemId] = editItem ? editItem.split(".") : [];

  const editDocumentData = Utils.arrayFindById(documentsData, editItemId);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handleItemDetailClose = useCallback(() => {
    setEnvVars({ editItem: null });
  }, [setEnvVars]);

  const handleListItemClick = useCallback((itemId) => {
    setEnvVars({ editItem: itemId });
  }, [setEnvVars]);

  const handleFilesChange = useCallback(async(files) => {
    if (!files) return;

    const modalResult = await showUploadDocumentsModal({ files });

    if (modalResult) {
      const successful = await uploadDocuments(modalResult);

      if (successful) refetchDocuments();
    }
  }, [showUploadDocumentsModal, uploadDocuments, refetchDocuments]);

  useEffect(() => {
    if (documentsFetching
      || documentsData.length
      || !documentsStats.current) {
      return;
    }
    setEnvVars({ page: Math.ceil(documentsStats.current / limit) });
  }, [
    page,
    limit,
    documentsFetching,
    documentsData.length,
    documentsStats,
    setEnvVars
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (!documentsFetching && editItemId && !editDocumentData && !envVars.text) {
      setEnvVars({ text: editItemId });
    }
  }, [documentsFetching, editDocumentData, editItemId, envVars.text, setEnvVars]);

  return (
    <>
      <Filters />
      <div className={classNames(Css.documentsPage, CommonCss.mobileContainer)}>
        <div className={Css.content}>
          {documentsData.length
            ? Utils.arraySort(documentsData, "status", true).map((documentData) => (
              <ListItem key={documentData.id} data={documentData} onItemClick={handleListItemClick} />
            ))
            : (
              documentsFetching
                ? <Preloader />
                : <NoDataContent title={uiTexts.noDocuments} />
            )}
        </div>
        <PageActionButtons>
          {Utils.checkIsNativeAndroid() && (
            <UploadFilesButton
              large outline
              data-loading={documentsCurrentUploadCount ? "" : undefined}
              disabled={documentsFetching || documentsCurrentUploadCount}
              icon={documentsCurrentUploadCount ? Icons.Spinner : Icons.Camera}
              capture="environment"
              accept={["image/*"]}
              onChange={handleFilesChange}>
              {uiTexts.takePhoto}
            </UploadFilesButton>
          )}
          <UploadFilesButton
            primary multiple
            data-loading={documentsCurrentUploadCount ? "" : undefined}
            disabled={documentsFetching || documentsCurrentUploadCount}
            icon={documentsCurrentUploadCount ? Icons.Spinner : Icons.FilePlus}
            accept={FILE_DROP_ALLOWED_TYPES}
            onChange={handleFilesChange}>
            {uiTexts.importDocuments}
          </UploadFilesButton>
        </PageActionButtons>
        {(documentsStats.current > Number(limit)) && (
          <Pagination
            showFirstLast={false}
            className={Css.pagination}
            count={documentsStats.current}
            page={Number(page)}
            maxDisplayedPages={MAX_DISPLAYED_PAGES}
            pageSize={Number(limit)}
            disabled={documentsFetching}
            onChange={handlePageChange} />
        )}
        {editItemId && (
          <ListItemDetails
            documentId={editItemId}
            data={editDocumentData}
            onClose={handleItemDetailClose} />
        )}
        {uploadDocumentsModalData && (
          <UploadDocumentsModal
            data={uploadDocumentsModalData}
            onClose={onUploadDocumentsModalClose} />
        )}
      </div>
    </>
  );
};

export default React.memo(DocumentsPage);
