import { useCallback } from "react";
import { useDispatch } from "react-redux";
import DocumentsActions from "actions/DocumentsActions";

const useUploadDocuments = () => {
  const dispatch = useDispatch();

  return useCallback((result) => {
    if (result.cloudService) {
      switch (result.cloudService) {
        case "gdrive":
          return dispatch(DocumentsActions.importCloudDocuments(
            result.cloudService,
            result.type,
            result.paymentType,
            result.googleDocs.map(({ id, name, mimeType }) => ({
              fileId: id,
              fileName: name,
              fileMimeType: mimeType,
              oAuthToken: result.oAuthToken
            })),
            result.merge
          ));
        case "dropbox":
          return dispatch(DocumentsActions.importCloudDocuments(
            result.cloudService,
            result.type,
            result.paymentType,
            result.filesLinks.map(({ link, name, mimeType }) => ({
              fileLink: link,
              fileName: name,
              fileMimeType: mimeType
            })),
            result.merge
          ));
        default:
          return null;
      }
    }
    if (result.files) {
      const payload = {
        type: result.type,
        merge: result.merge,
        ...(result.paymentType ? { paymentType: result.paymentType } : {})
      };

      return dispatch(DocumentsActions.uploadDocuments(result.files, payload, true));
    }

    return dispatch(DocumentsActions.addNewDocument(result));
  }, [dispatch]);
};

export default useUploadDocuments;
