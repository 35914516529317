import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import ConfidencePercents from "nlib/common/ConfidencePercents";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { DATETIME_FORMATS: { DATE_TEXT } } = Constants;

const {
  STATUSES: { TO_EXTRACT, EXPORTED, TO_REVIEW },
  DOCUMENT_TYPES: { INVOICE, BILL },
  DOCUMENT_PAYMENT_TYPES: { BUY }
} = DataConstants;

const StatusContent = ({ status, recogniseData, duplicatedDocumentsIds }) => {
  const { uiTexts } = useSelector(getTextsData);

  const toReviewStatus = status === TO_REVIEW;

  const errorState = status === TO_EXTRACT && recogniseData.status === "error";

  const duplicatedDocument = Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length;

  if (errorState) {
    return (
      <Badge negative className={Css.status} value={uiTexts.error} />
    );
  }

  if (duplicatedDocument) {
    return (
      <Badge warning className={Css.status} value={uiTexts.duplicate} />
    );
  }

  if (toReviewStatus && !recogniseData.confidence) {
    return (
      <Badge primary className={Css.status} value={uiTexts.edited} />
    );
  }

  if (recogniseData.confidence) {
    return (
      <ConfidencePercents
        className={`${Css.status} ${Css.percents}`}
        value={recogniseData.averageConfidence}
        component={Badge} />
    );
  }

  return null;
};

const ListItem = ({ data = {}, onItemClick }) => {
  const {
    id: documentId,
    address: { name: vendorName } = {},
    attachment: { key: attachmentKey } = {},
    type,
    status,
    currency,
    vendorId,
    issueDate,
    amountVat,
    amountBase,
    paymentType,
    recogniseData,
    originalDocumentId,
    duplicatedDocumentsIds
  } = data;

  const { uiTexts } = useSelector(getTextsData);

  const { settings: { allowClientPayeeSelection } = {} } = useSelector(getSelectedBusinessData);

  const extractionStatus = status === TO_EXTRACT;

  const exportedStatus = status === EXPORTED;

  const hasDuplicates = !!(Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length);

  const amount = (+amountBase || 0) + (+amountVat || 0);

  const buyPayment = paymentType === BUY;

  const billType = paymentType === BUY && type === INVOICE;

  const typeContent = (paymentType && type && (billType ? BILL : type));

  const vendorContent = paymentType && vendorName;

  const { labelLangId: statusTitleLangId } = DocumentsStatuses.getStatusData(status) || {};

  const handleListItemClick = useCallback(() => {
    onItemClick(documentId);
  }, [documentId, onItemClick]);

  return (
    <>
      <div className={Css.listItem} onClick={handleListItemClick}>
        <div className={Css.header}>
          <div className={Css.info}>
            {extractionStatus
              ? (
                <div>
                  <Icons.Cloud className={Css.warning} />
                  <span className={Css.neutral}>{uiTexts.processing}...</span>
                </div>
              )
              : <>
                {hasDuplicates
                  ? <Icons.Copy className={Css.warning} />
                  : <Icons.FileText className={attachmentKey ? "" : Css.negative} />}
                <span>
                  {uiTexts[typeContent] || uiTexts.unknown}
                  {`: ${originalDocumentId}`}
                </span>
              </>}
          </div>
          <div className={Css.amount}>
            {status !== TO_EXTRACT && (
              <div
                className={(paymentType && (amount || amount === 0))
                  ? CommonCss.coloredNumber
                  : (paymentType ? "" : CommonCss.mediumDarkText)}
                data-negative={buyPayment ? "" : undefined}>
                {Utils.toMoneyString(amount, currency)}
              </div>
            )}
          </div>
        </div>
        <div className={Css.content}>
          <div className={classNames(Css.timestamp, !issueDate && CommonCss.neutralText)}>
            {issueDate ? moment.utc(issueDate).format(DATE_TEXT) : uiTexts.noDate}
          </div>
          {!!statusTitleLangId && (
            <div className={Css.documentStatus}>
              <StatusContent
                status={status}
                recogniseData={recogniseData}
                duplicatedDocumentsIds={duplicatedDocumentsIds} />
            </div>
          )}
          <div className={Css.vendor}>
            {vendorContent
              ? ((exportedStatus || !allowClientPayeeSelection) ? <Icons.User /> : (
                <>
                  {vendorId
                    ? <Icons.User className={CommonCss.positiveText} />
                    : <Icons.UserPlus className={CommonCss.highlightText} />}
                </>
              ))
              : null}
            <span className={vendorContent ? undefined : CommonCss.neutralText}>{vendorContent || uiTexts.noPayee}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ListItem);
